import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-error-promcode-dialog',
	templateUrl: './error-promcode-dialog.component.html',
	styleUrls: ['./error-promcode-dialog.component.css'],
})
export class ErrorPromcodeDialogComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}
	static async show(modalCtrl: ModalController): Promise<void> {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: ErrorPromcodeDialogComponent,
			componentProps: {},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	ngOnInit() {}
	close() {
		this.modalCtrl.dismiss();
	}
}
