import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';
import * as JsBarcode from 'jsbarcode';
import PromoCode from 'src/smoothr-web-app-core/models/PromoCode';

@Component({
	selector: 'app-sot-promo-barcode-dialog',
	templateUrl: './sot-promo-barcode-dialog.component.html',
	styleUrls: ['./sot-promo-barcode-dialog.component.css'],
})
export class SotPromoBarcodeDialogComponent implements OnInit {
	public promoCode: PromoCode;
	constructor(private modalCtrl: ModalController) {}
	static async show(modalCtrl: ModalController, promoCode: PromoCode): Promise<void> {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: SotPromoBarcodeDialogComponent,
			componentProps: {
				promoCode: promoCode,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	ngOnInit() {
		this.generateBarcode();
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
	generateBarcode(): void {
		console.log(this.promoCode?._id);
		const canvas = document.getElementById('barcode') as HTMLCanvasElement;
		if (canvas) {
			JsBarcode(canvas, 'smoothr://promoCode/' + this.promoCode?._id, {
				format: 'CODE128', // Barcode format, e.g., 'EAN13', 'UPC', etc.
				displayValue: true, // Display the URL as text below the barcode
			});
		}
	}
}
