import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { OrderListAction } from '../../enums/OrderListAction';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import { getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-order-list',
	templateUrl: './order-list.component.html',
	styleUrls: ['order-list.component.scss'],
})
export class OrderListComponent implements DoCheck {
	@Output() action = new EventEmitter<OrderListAction>();
	@Input() editable = true;
	OrderUtils = OrderUtils;
	isSomeOptionFullPrice = false;
	needsConfiguration = false;
	articleGroupPrice = 0;
	numberToCurrency = numberToCurrency;
	private prevPromoState = false;
	private prevQuantity = 1;
	public isValidGroup = true;
	constructor(private cd: ChangeDetectorRef) {}

	private _articleGroup: ArticleGroup;

	get articleGroup(): ArticleGroup {
		return this._articleGroup;
	}

	@Input()
	set articleGroup(value: ArticleGroup) {
		this._articleGroup = this.mapGroup(value);
		this.reloadArticle();
		this.isValidGroup = this.checkIsValid(value);
	}

	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(value: Order) {
		this._order = value;
		this._articleGroup = this.mapGroup(this.articleGroup);

		this.reloadArticle();
	}
	@Input() venue: Venue;

	private checkIsValid(articleGroup: ArticleGroup) {
		return ValidationUtils.areGroupsValid(articleGroup, articleGroup.article.groups);
	}
	mapGroup(articleGroup: ArticleGroup) {
		if (!this.venue || !this.order) {
			return articleGroup;
		}
		if (this.venue) {
			const foundArticle = this.venue.articleCategories
				.map(it => {
					if (it.visible) {
						const foundArticle = it.articles.find(ar => ar._id === articleGroup.article._id);

						return foundArticle ?? null;
					} else {
						return null;
					}
				})
				.filter(it => !!it);
			if (foundArticle.length === 1) {
				const test = JSON.parse(JSON.stringify(articleGroup)) as ArticleGroup;
				test.article = JSON.parse(JSON.stringify(foundArticle[0]));

				return test;
			}
			return articleGroup;
		}
		return articleGroup;
	}
	edit() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.edit);
	}

	isEditable(): boolean {
		return this.editable;
	}

	delete() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.delete);
	}

	async note() {
		if (!this.editable) {
			return;
		}
		this.action.emit(OrderListAction.note);
	}

	add() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.add);
	}

	remove() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.remove);
	}

	isOptionFullPrice(articleOption: ArticleOption): boolean {
		const group = this.articleGroup.article.groups.find(grp => grp._id === articleOption.group);
		return group && group.displayIdentifiers && group.displayIdentifiers.findIndex(di => DisplayIdentifier.fullPrice === di) >= 0;
	}

	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}

	reloadArticle() {
		this.needsConfiguration = false;
		this.isSomeOptionFullPrice = false;
		this.articleGroupPrice = 0;
		this.prevPromoState = false;
		this.prevQuantity = 1;
		if (!this.articleGroup || !this.order || !this.order.preorder) {
			this.articleGroupPrice = 0;
			return;
		}
		this.prevQuantity = this.articleGroup.quantity;
		this.prevPromoState = this.articleGroup.isPromo;
		this.needsConfiguration = !ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups);
		for (const articleOption of this.articleGroup.groups) {
			if (this.isOptionFullPrice(articleOption)) {
				this.isSomeOptionFullPrice = true;
			}
		}
		this.articleGroupPrice = OrderUtils.totalPrice(this.articleGroup, this.order.type, this.order.preorder.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.articleGroup.isPromo) {
			this.articleGroupPrice -= OrderUtils.bogoPrice(this.articleGroup, this.order.type, this.order.preorder.type);
		}
	}

	ngDoCheck(): void {
		if (this.prevPromoState !== this.articleGroup.isPromo) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
		if (this.prevQuantity !== this.articleGroup.quantity) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
	}

	priceOfOption(articleOption: ArticleOption): number {
		if (articleOption.group === '6615e6b444b15eee9c5e6738' || articleOption.group === '6615e6c344b15eee9c5e6e7d') {
			return 0;
		}
		if (articleOption && articleOption?.article?.name?.de?.includes('Pfand')) {
			return 0;
		}
		if (
			articleOption?.article?.displayPriceByType?.preorder &&
			numberD(articleOption?.article?.displayPriceByType?.preorder?.takeAway) === 0
		) {
			return 0;
		}
		let price = getPrice(articleOption.article, this.order.type, this.order.preorder?.type);
		// if (this.isOptionFullPrice(articleOption)) {
		// 	price = price + this.articleGroupPrice;
		// 	if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.articleGroup.isPromo) {
		// 		return 0;
		// 	}
		// }
		if (this.articleGroup) {
			const findGroup = this.articleGroup.article.groups.find(it => it._id === articleOption.group);
			if (findGroup) {
				if (findGroup.tags.find(it => it.identifier === 'half_pizza')) {
					return 0;
				}
			}
		}

		return price * articleOption.quantity;
	}
}
