// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 90%;
  margin: 15px auto;
}

h3 {
  font-family: AppFontBold, sans-serif;
  margin-top: 15px;
}

ion-textarea {
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  font-size: 14px;
  --width: 100% !important;
  width: 100% !important;
  --padding-start: 10px;
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.button-block .button {
  width: 150px;
  width: 45%;
}
.button-block_dismiss {
  --background: #fff;
  border-radius: 50px;
  --background-hover: #fff;
  --riple-color: #fff;
  --background-focused: #fff;
  color: var(--ion-color-primary);
}

ion-icon {
  width: 19px;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/app/components/note-article-modal/note-article-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;AACJ;;AACA;EACI,oCAAA;EACA,gBAAA;AAEJ;;AAAA;EACI,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,wBAAA;EACA,sBAAA;EACA,qBAAA;AAGJ;;AADA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAIJ;AAFI;EACI,YAAA;EACA,UAAA;AAIR;AADI;EACI,kBAAA;EAEA,mBAAA;EACA,wBAAA;EACA,mBAAA;EACA,0BAAA;EACA,+BAAA;AAER;;AACA;EACI,WAAA;EACA,WAAA;AAEJ","sourcesContent":[".wrapper{\n    width: 90%;\n    margin: 15px auto;\n}\nh3 {\n    font-family: AppFontBold, sans-serif;\n    margin-top: 15px;\n}\nion-textarea {\n    border: 1px solid #E6E6E6;\n    border-radius: 10px;\n    font-size: 14px;\n    --width: 100% !important;\n    width: 100% !important;\n    --padding-start: 10px;\n}\n.button-block{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 10px;\n    \n    .button {\n        width: 150px;\n        width: 45%;\n    }\n\n    &_dismiss {\n        --background: #fff;\n        // border: 2px solid var(--ion-color-primary);\n        border-radius: 50px;\n        --background-hover: #fff;\n        --riple-color: #fff;\n        --background-focused: #fff;\n        color: var(--ion-color-primary);\n    }\n}\nion-icon{\n    width: 19px;\n    z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
