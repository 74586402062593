import { PaymentMethod } from '../enums/PaymentMethod';
import { PaymentStatus } from './PaymentStatus';
import { WirecardTransactionType } from './WirecardTransactionType';

export default class Payment {
	// tslint:disable-next-line:variable-name
	_id: string;
	session: string;
	createdAt: Date;
	updatedAt: Date;
	orders: any = [];
	transactionType: WirecardTransactionType;
	status: PaymentStatus;
	method: PaymentMethod;
	sum: number;
	totalSum: number;
	tip = 0;
	wirecardTransactionId: string;
	requestId: string;
	paymentSignatureV2: string;
	merchantId: string;
	merchantName: string;
	creditorId: string;
	paymentMandat: string;
	currency: string;
	basicAuthorization: string;
	posMerchantReceipt: string;
	posCustomerReceipt: string;
	overwriteSessionsByApp = true;
	saveOcp = false;
	response: any = null;
	url: string;
	paypal: {
		orderId: string;
	};
	vrPayment: {
		checkoutId: string;
	};
	wpwlOptions: any;
	adyenPayment?: {
		card?: {
			month: string;
			year: string;
			code: string;
			number: string;
		};
		redirectUrl?: string;
		cardPaymentSessionMd?: string;
		cardAuthRequestPaReq?: string;
		gpToken?: string;
	};
	isEmployeePayment: boolean;
	worldlinePayment?: {
		paymentPage: string;
	};
	isMobileApp: boolean;
}
